import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/var/jenkins_home/workspace/affilate_mono_repo_nyaskosnoren/gatsby/src/components/mdxLayout.js";
import { graphql, Link } from "gatsby";
import Image from "../components/image.js";
export const query = graphql`
  query {
    file(relativePath: { eq: "faktaomskosnoren.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fixed(width: 500) {
          base64
          aspectRatio
          width
          height
          src
          srcSet
        }
      }
    }
  }
`;
export const _frontmatter = {};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "fakta-om-skosnören"
    }}>{`Fakta Om Skosnören`}</h1>
    <p>{`Fakta om skosnören har visat att Skosnören länge har använts utav
människan för att på ett enkelt och smidigt sätt fästa skor runt fötterna.
Genom att dra ett snöre genom ett antal öglor på skon och sedan med hjälp
av en knut spänna och säkra snöret kan man få skon att sitta kvar på
foten. Idag är det kardborreband som är den vanligaste konkurrenten till
skosnören. Dessa används dock mest utav yngre barn som ännu inte har lärt
sig att knyta skorna rätt. Ett alternativ till kardborreband är att
använda sig utav elastiska skosnören tillsammans med ett spänne. Elastiska
skosnören och kardborreband kan även vara populärt hos äldre personer, som
kanske inte längre har den fingermotoriken som krävs för att knyta.`}</p>
    <h2 {...{
      "id": "historia"
    }}>{`Historia`}</h2>
    <p>{`Tiden då skosnörets historia tog sin början är svår att fastställa då det
inte finns mycket fakta om skosnören från de tidiga åren. Det man vet är
att ca år 2000 f.Kr. så har de antika mesopotamierna bitar av läder under
fötterna för att skydda mot skador och smuts. Dessa fästes runt foten och
fotleden med ett snöre som troligen var gjort utav råhud. Utan detta snöre
skulle läderbiten under foten inte kunna hållas på plats och därmed vara
värdelös.`}</p>
    <p>{`Det som däremot ledde till att skosnöret fick allt större spridning,
framförallt i Europa, var romarna och framförallt de romerska soldaterna.
Soldaternas långa marscher krävde skydd för fötterna, och precis som de
antika mesopotamierna använde de sig utav en slitstark sula, vanligtvis av
läder, och sedan råhud för att fästa läderbiten runt foten och fotleden.
Detta är mest att jämföra med dagens sandaler. Det var inte bara
soldaterna som använde sig utav skodon, även vanliga stadsbor använde sig
utav en varierande mängd skor, även ibland päls-beklädda skor, som ofta
inte använde sig utav skosnöre, utan istället stift.`}</p>
    <p>{`Senare, under den Elisabetanska eran, var skor ofta säkrade med remmar,
snören, spännen och pins, både vanliga enkla och juvelprydda. För de
amerikanska kolonisterna kunde skosnören betyda status, varför man ofta
säkrade sina skor med färgat läder eller juvelprydda spännen.`}</p>
    <p>{`Museum of London har dokumenterat olika exempel av skodon och skosnören
ända bak till 1100-talet. Denna dokumentation visar tydligt att skosnören
användes på skorna, och att dessa löpte genom en serie utav öglor och hål
på ovansidan av skon från sida till sida, precis som dagens skosnören.`}</p>
    <h2 {...{
      "id": "skosnörets-konstruktion"
    }}>{`Skosnörets konstruktion`}</h2>
    <p>{`Traditionella skosnören är ofta gjorda utav läder, bomull, jute eller
hampa, precis som vanliga rep. På senare tid har man däremot börjat
använda sig mer utav syntetisk fiber då detta ofta är billigare. En
nackdel med syntetiska skosnören, som nog många har upplevt, är att de är
halare och därför har en tendens att gå upp enklare. Förutom priset har
även syntetiska skosnören bättre hållbarhet än traditionell fiber, då de
är halare och därför inte skavs sönder lika lätt. Syntetiska skosnören är
också mer tåliga mot fukt och då inte i samma utsträckning möglar.`}</p>
    <p>{`I varje ände av skosnöret finns en dubb, vilket ser ut som om någon lindat
tejp runt ena änden. Detta är för att förhindra att skosnöret, som ofta
består av flera tunna trådar, ska tvinna upp sig och förstöras. Dubben gör
det också mycket enklare att trä snöret genom hålen på skorna.`}</p>
    <p>{`Det är även vanligt att man efterbehandlar skosnören för att ge ytan mer
friktion för att de ska hålla knutar bättre.`}</p>
    <h2 {...{
      "id": "olika-typer"
    }}>{`Olika typer`}</h2>
    <p>{`Förutom de vanliga, klassiska skosnörena, vanligtvis gjorda utav syntetisk
fiber, så finns ett antal elastiska skosnören.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Traditionella elastiska skosnören som fungerar precis som vanliga där man
kan knyta och knyta upp. Dessa kan däremot ofta komma med ett permanent
”spänne” som skosnöret löper igenom. Skosnöret kan låsas fast i spännet och
du slipper då knyta dina skor.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`”Knotiga” skosnören har varierande tjocklek med ”tjocka” sektioner och
”smala” sektioner om vart annat. Dessa löper sedan genom öljetter och med
hjälp av de olika tjocka sektionerna kan man spänna fast skosnöret utan att
behöva knyta.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Tvistade skosnören ser ut som en spiral och spiralen fungerar som ett
naturligt stopp i öglorna. Snöret går enkelt att dra genom öglorna för hand
men stannar sedan på plats tack vare sin helix-formade konstruktion.`}</p>
        <p parentName="li">{`Elastiska skosnören gör det enkelt att snabbt ta av och på sig skorna och
därför har de blivit ett populärt val bland barn och äldre.`}</p>
        <p parentName="li">{`Det finns även platta skosnören, som ofta håller knutar bättre än vanliga
traditionella runda.`}</p>
      </li>
    </ul>
    <Image fixed={props.data.file.childImageSharp.fixed} alt="Skor med platta skosnören" title="Skor med platta skosnören" rowOne="Skapare: Marcos André" rowTwo="Källa: http://commons.wikimedia.org/wiki/File:Cross-laced_white_sneaker_shoelaces.jpg" mdxType="Image" />
    <h2 {...{
      "id": "myter"
    }}>{`Myter`}</h2>
    <p>{`En populär myt om skosnören är att soldater som slogs för Storbritannien, och
japanska trupper använde sig utav olika typer av snörning. När soldaterna kröp
på marken kunde ge genom att studera skosnörena och snörningen på andra
soldater avgöra om de var allierade eller fiender. Att knyta sina skosnören
rätt kunde därför betyda skillnaden mellan liv och död. Huruvida denna myt om
skosnören är sann eller inte är svårt att avgöra då det inte finns mycket
fakta om skosnören och soldater.`}</p>
    <p>{`Vill du hitta mer fakta om skosnören så har den engelska Wikipedia-sidan
mycket nyttigt.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      